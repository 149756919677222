<template>
  <div class="box" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="selection_box bg_fff p_in_10 br_5">
      <el-radio-group class="m_tb_20"
                      v-model="calssifyIdItem[index]"
                      v-for="(val,index) of selectMenu"
                      :key="index"
                      v-on:change="yyy"
                      size="small"
      >
        <el-radio-button v-for="item of val" :key="item.id" :label="item.id">
          {{ item.name }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="fs_18 fw_bold m_tb_20">目录</div>
    <div class="p_in_20 bg_fff" v-loading="loading">
      <el-collapse :v-model="0">
        <el-collapse-item :title="catalogue.name"
                          :name="catalogue.id"
                          v-for="(catalogue,index) of resourceCatalogue"
                          :key="index"
                          @click.native="resourceList_click(catalogue)"
        >
          <div class="m_tb_10" v-for="item of catalogue.children" :key="item.id">
            <router-link :to="{path: '/courseDetail', query: {courseId: item.id}}">
              <li class="course_li">
                {{ item.name }}
              </li>
            </router-link>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import {getResourcesContents} from "@/api/index"
import {getResourcesCourse} from "@/api/search";
// import xxx from '@/utils/data'

export default {
  name: '',
  data: () => {
    return {
      // 所有数据
      menuData: [],
      // 层级目录
      selectMenu: [],
      // 选中的选项id
      calssifyIdItem: [],
      // 资源目录
      resourceCatalogue: [],
      // 课程列表
      courseList: [],
      // 加载
      loading: false,
      fullscreenLoading: false
    }
  },
  created() {
    this.getResourcesContents()
  },
  methods: {
    async getResourcesContents() {
      this.fullscreenLoading = true
      await getResourcesContents({calssifyId: "1518399756408508417", type: 1}).then((res) => {
        this.xxx(res.data, 0)
        this.fullscreenLoading = false
      })
      this.yyy(this.$route.query.id)
      this.$forceUpdate()
    },

    getList() {
      let params = {
        isTelecast: 2,
        contentsId: this.calssifyIdItem.at(-1),
        userId: '',
        name: '',
        current: 1,
        size: 50,
      }
      getResourcesCourse(params).then(() => {
      })
    },
    ////////////////////////////////
    xxx(data, i) {
      this.resourceCatalogue = []
      if (data.length === 0) {
        return
      }
      let arr = []
      data.map(item => {
        if (item.children.length !== 0) {
          if (data[0].id === item.id) {
            this.calssifyIdItem[i] = item.id
          }
          arr.push(item)
        } else {
          let obj = {name: item.name, id: item.id, children: item.children}
          this.resourceCatalogue.push(obj)
        }
      })
      this.selectMenu[i] = arr
      i++

      if (data[0].children.length !== 0) {
        this.xxx(data[0].children, i)
      }
    },

    yyy(id) {
      let data = this.selectMenu
      data.map((item, index) => {
        item.map(content => {
          if (content.id === id) {
            if (index === 0) {
              this.calssifyIdItem[0] = id
            }
            this.calssifyIdItem = this.calssifyIdItem.splice(0, index + 1)
            this.xxx(content.children, index + 1)
          }
        })
      })

      // this.getList()
    },
    resourceList_click(data) {
      let params = {
        isTelecast: 2,
        contentsId: data.id,
        userId: '',
        name: '',
        current: 1,
        size: 50,
      }

      this.resourceCatalogue.map(item => {
        if (data.id == item.id) {
          if (item.children.length) return

          this.loading = true
          getResourcesCourse(params).then(res => {
            item.children = res.data.records
            this.$forceUpdate()
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.box {
  width  : 120rem;
  margin : 1rem auto;
}

.selection_box {
  width : 1200px;
}

::deep .el-radio-button__inner {
  border        : 1px solid #f2f2f2;
  border-radius : 5px;
}

.el-radio-button {
  margin-right : 10px;
}

::deep .el-radio-button:last-child .el-radio-button__inner {
  border-radius : 5px;
}

::deep .el-radio-button:first-child .el-radio-button__inner {
  border-radius : 5px;
  border        : 1px solid #f2f2f2;
}

.el-radio-group {
  display : block;
}

::deep .el-collapse-item__header {
  font-size   : 16px;
  font-weight : bold;
}

::deep .el-radio-button__inner {
  border        : 0;
  border-radius : 2rem;
}

::deep .el-radio-button:first-child .el-radio-button__inner {
  border        : 0;
  border-radius : 2rem;
}

.course_li {
  padding       : 10px 10px;
  border-radius : 5px;
}

.course_li:hover {
  background-color : #f2f2f2;
}
</style>
